<template>
    <div>
        <div class="row">
            <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                <user-email-verification />                   
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-lg-12">
                <earning-overview :earning="result.earning" />
            </div>
        </div> -->
        <div class="row">
            <div class="col-lg-12">
               <order-list-table />
            </div>
        </div>
    </div>
</template>
<script>

import UserEmailVerification from "@/components/UserEmailVerification.vue"
import EarningOverview from '@/components/widgets/EarningOverview.vue';
import OrderListTable from "@/components/order/OrderListTable.vue"

export default {
    components: {
        UserEmailVerification,
        EarningOverview,
        OrderListTable
    },
    data() {
        return {
            isLoading:false,
            result: {
                "earning": {
                    "last_month": 0,
                    "current_month": 0,
                }
            },
        };
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
    created() {
        // this.$http.get(`/analytics`)
        // .then((response) => {
        //     if(response.data.success){
        //         this.result = response.data.data
        //         this.isLoading = true
        //     }
        // })
    }
};
</script>
